/* https://www.geeksforgeeks.org/how-to-include-a-font-ttf-using-css/ */
@font-face {
    font-family: Fredericka the Great;
    src: url(../../fonts/FrederickatheGreat-Regular.ttf);
}
@font-face {
    font-family: Jolly Lodger;
    src: url(../../fonts/JollyLodger-Regular.ttf);
}

.navbar { 
 opacity: 0.95;
}

.navbar-brand {
    opacity: 1;
    box-shadow: black 1px 1px 2px, blue 2px 2px 25px, darkblue 0 0 5px ;;
}
.navbar-username {
    /* colors: */
    /* light grey #959A98 */
    /* dark grey #343A40 */
    /* background-color: #343A40; */
    /* background-color: #959A98; */
    /* border-style: dotted; */
    /* border-style: none; */
    /* color: #343A40; */
    font-size: 1.75em;
    /* background-image: radial-gradient(circle at center, #343A40 20%, #959A98 150%); */
    color: white;
    /* shows a white text with black, blue, and darkblue shadow: */
    /* text-shadow: 1px 1px 2px black, 0 0 25px black, 0 0 5px black; */
    /* shows the shadows: https://developer.mozilla.org/pt-BR/docs/Web/CSS/text-shadow?msclkid=b53fac85bd8611ecb350117e81287dd7 */
    text-shadow: black 1px 1px 2px, blue 2px 2px 25px, darkblue 0 0 5px ;
    /* removes border when button is clicked: */
    outline: none;
    font-family: 'Jolly Lodger';
}