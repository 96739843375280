.generalCard {
  /* margin-top: 90px; */
  opacity: 0.90
 /* height: 100vh; */
 /* min-width: 100ch; */
}

.card-header {
  font-size: 1.2em;
  font-weight: bold;
  border-style: none;
};

 
  /* .card-header {
    color: red;
  } */
  
  /* .card-body {
    color: red;
  } */
  
  /* .card-status {
    color: red !important;
    margin-top: 100px;
  } */
  