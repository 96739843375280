
.statement-currentUser {
    font-weight: bold;
    text-decoration: underline; 
  }
  
  .statementEntries-entry-positive {
    padding: 0px 0px 0px 10px;
  /*     margin-left: 10px; */
    background-color: lightgrey;
  /*   list-style-type: disc; */
  /*   list-style-type: circle; */
  /*   list-style-type: space-counter; */
  /*   try https://emojipedia.org/ */
  /*   list-style-type: "\1F44D"; // thumbs up sign */
    list-style-type: "➕";
  }
  
  .statementEntries-entry-negative {
    padding: 0px 0px 0px 10px;
    background-color: lightgrey;
    list-style-type: "➖";
  }
  
  .bankStatement--title {
    font-weight: bold;
  }

  .row {
    display: flex;
    /* justify-content: space-between; */
  }

  .custom-col-1 {
    flex: 0 0 5%; /* Ajuste a largura % conforme necessário */
    margin-right: 5px; /* Adicione margem direita ou esquerda para espaçamento entre as colunas */
    margin-left: 10px;
  }

  .custom-col-2 {
    flex: 0 0 15%; /* Ajuste a largura % conforme necessário */
    margin-right: 5px; /* Adicione margem direita ou esquerda para espaçamento entre as colunas */
    margin-left: 10px;
  }

  .custom-col-3 {
    flex: 0 0 25%; /* Ajuste a largura % conforme necessário */
    margin-right: 5px; /* Adicione margem direita ou esquerda para espaçamento entre as colunas */
    margin-left: 10px;
  }

  .custom-col-4 {
    flex: 0 0; /* Defina a largura da quarta coluna como 25% */
  }  
