.erroStatus {
  color: darkred;
  font-weight: bold;
};

.erroCreateAccount {
    color: darkred;
    font-weight: bold;
  };

/* line 21, "variables" */
:root {
  --gray-900: #111114;
  --gray-800: #222224;
  --gray-700: #444444;
  --gray-600: #666664;
  --gray-500: #888884;
  --gray-400: #aaaaa4;
  --gray-300: #ccccc4;
  --gray-200: #e2e2e4;
  --gray-100: #f9f9f9;
  --white: white;
  --blue: #4299e1;
  --red: #910101;
  --text-decoration-color: var(--gray-400);
  --text-color: var(--gray-800);
  --focus-ring-color: var(--blue);
  --border-radius: 4px;
}

  /* line: 165 */
  /* Form
  ––––––––––––––––––––––––––––––––– */
  label {
    /* font-weight: bold; */
    display: flex;
  }

  /* line 600: */
  .input-feedback {
    color: red;
    margin-top: 0.25rem;
    font-weight: bold;
  }
  
  .error {
    color: red;
    font-weight: bold;
  }
  
  .success {
    color: green;
    font-weight: bold;
  }


  /* line 617: */
  .valid input[type='email'],
  .valid input[type='text'],
  .valid input[type='number'],
  .valid input[type='password'] {
    border-color: green;
    /* font-weight: bold; */
    border-width: 3px;
  }
  
  .valid .feedback {
    color: green;
    font-weight: bold;
  }
  
  .invalid input[type='email'],
  .invalid input[type='text'],
  .invalid input[type='number'],
  .invalid input[type='password'] {
    border-color: var(--red);
    border-width: 3px;
  }
  
  .invalid .feedback {
    color: #b60202;
  }

/* line 648: */
button.outline,
button[type='reset'],
input[type='reset'] {
  background-color: var(--gray-200);
  border: 2px solid var(--gray-200);
  color: var(--gray-900); /* not here */
}
button.outline:hover,
button[type='reset']:hover,
input[type='reset']:hover {
  background-color: var(--gray-300);
  border: 2px solid var(--gray-300);
}

@media (prefers-color-scheme: dark) {
  button.outline,
  button[type='reset'],
  input[type='reset'] {
    background-color: var(--gray-800);
    border: 2px solid var(--gray-700);
    color: var(--gray-100); /* not here */
  }

  button.outline:hover,
  button[type='reset']:hover,
  input[type='reset']:hover {
    background-color: var(--gray-900);
    border: 2px solid var(--gray-700);
    color: var(--gray-200); /* not here */
    cursor: pointer;
  }
}

input[type='email'],
input[type='text'],
input[type='number'],
input[type='password'],
textarea,
select {
  width: 100%;
}

label.checkbox {
  font-weight: normal;
}

button[type='submit'],
button[type='reset'] {
  margin-top: 1rem;
  margin-bottom: 0;
}

/* line 754: */
.flex {
  display: flex;
  margin-top: 0px;
  margin-left: 0px;
  padding: 0px;

}

.space-between {
  justify-content: space-between;
}

.items-center {
  align-items: center;
}


/* MY NEW STYLING */
label {
  /* font-weight: bold; */
  /* color: white; */
  display: flex;
  /* background-color: blue; */
}

.form-control {
  /* background-color: transparent; */
  padding: 0px;
  border: 0px;
}

input {
  height: 35px;
  /* border: 10px; */
  border-color: gray;

}

.formik-control {
  margin: 10px 0px 10px 0px;
  border-radius: 50%;
}

/* #formik-reset {
  margin-left: 10px;
} */

input {
  border-radius: 5px;
}

button[type='reset'] {
  margin-left: 10px;
}

#name-help {
  color: white;
  font-style: italic;
  text-align: right;
}

#email-help {
  color: white;
  font-style: italic;
  text-align: right;
}

#password-help {
  color: white;
  font-style: italic;
  text-align: right;
}

.feedback {
  font-weight: bold;
}

/* #password-feedback {
  font-size: 1.5rem;
  font-weight: bold;
  color: lightgreen;
} */

.valid .feedback {
  font-size: 1.5rem;
  font-weight: bold;
  color: rgb(0, 255, 0);
}